<template>
  <div class="cropper-test">
    <h1>Cropper Testing 123</h1>

    <input type="file" accept="image/*" @change="onFileSelected" />

    <div class="preview-container">
      <h3>Final Cropped Image</h3>
      <img v-if="croppedPreviewUrl" :src="croppedPreviewUrl" class="preview-image" />
      <div v-else class="preview-placeholder">No image cropped yet</div>
    </div>

    <div v-if="showCropper" class="cropper-modal">
      <div class="cropper-container">
        <Cropper
          ref="cropper"
          :src="cropperImage"
          :stencil-component="circleStencilComponent"
          :stencil-props="{
            aspectRatio: 1,
            movable: true,
            resizable: true,
            stencilSize: { width: 300, height: 300 }
          }"
          @resize-start="onResizeStart"
          @resize="onResize"
          @resize-end="onResizeEnd"
          image-restriction="stencil"
          class="cropper"
        />

        <div class="cropper-actions">
          <button @click="cancelCrop">Cancel</button>
          <button @click="completeCrop" class="crop-button">Crop</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import CircleStencil from '@/components/CircleStencil.vue';

export default {
  name: 'CropperTest',
  components: {
    Cropper,
  },
  data() {
    return {
      showCropper: false,
      cropperImage: null,
      croppedPreviewUrl: null,
      stencilSize: null, // will be set dynamically
    };
  },
  computed: {
    circleStencilComponent() {
      return CircleStencil;
    },
  },
  methods: {
    onResizeStart() {
      // Just a placeholder for the event
    },

    onResize(event) {
      console.log('CropperTest received resize event:', event);
      
      // If we receive a directions object from our custom stencil
      if (event.directions) {
        console.log('Processing resize with directions:', event.directions);
        
        // The directions have already been fixed in the CircleStencil component
        // We don't need to modify them further, just let the cropper handle them
        return;
      }
      
      // Default handling for standard resize events
      console.log('Processing standard resize event');
      const size = Math.min(event.width, event.height);
      event.width = size;
      event.height = size;
    },

    onResizeEnd() {
      // Just a placeholder for the event
    },

    async onFileSelected(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.cropperImage = e.target.result;

          // Set default size dynamically
          this.stencilSize = {
            width: 300,
            height: 300,
          };

          this.showCropper = true;
        };
        reader.readAsDataURL(file);
      }
    },
    
    cancelCrop() {
      this.showCropper = false;
      this.cropperImage = null;
      this.croppedPreviewUrl = null;
    },
    
    completeCrop() {
      console.log('Completing crop...');
      try {
        const result = this.$refs.cropper.getResult({
          size: 'stencil',
          fillColor: null,
        });
        
        console.log('Crop result:', result);
        
        if (result?.canvas) {
          const canvas = result.canvas;
          const ctx = canvas.getContext('2d');
          const size = canvas.width;
          
          console.log('Canvas size:', size);
          
          // Apply circular mask
          ctx.globalCompositeOperation = 'destination-in';
          ctx.beginPath();
          ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
          ctx.closePath();
          ctx.fill();
          
          // Convert to data URL
          this.croppedPreviewUrl = canvas.toDataURL('image/png');
          console.log('Preview URL created');
          
          // Close the cropper
          this.showCropper = false;
          this.cropperImage = null;
        } else {
          console.error('No canvas in result');
        }
      } catch (error) {
        console.error('Error during crop:', error);
      }
    }
  },
};
</script>

<style scoped>
.cropper-test {
  padding: 20px;
}

.preview-container {
  margin-top: 20px;
  min-height: 150px;
}

.preview-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ccc;
}

.preview-placeholder {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #999;
  text-align: center;
  padding: 10px;
}

.cropper-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow-y: auto;
}

.cropper-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
}

.cropper {
  width: 100%;
  height: auto;
  max-height: 400px;
  margin-bottom: 20px;
  transform: translateZ(0);
}

.cropper-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

:deep(.vue-advanced-cropper__foreground) {
  background: transparent !important;
  opacity: 0 !important;
  content: none !important;
  display: none !important;
}

.crop-button {
  background-color: #376BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.crop-button:hover {
  background-color: #2f5ee0;
}

</style>
